<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>   
         <div class="page-desc">
            使用更新企业成员功能，则自动为未绑定/创建的企业人员创建系统账号。更新后可使用企业人员的账号登录当前系统，默认密码为 yestar1992
         </div>  
		 <el-tabs v-model="active" >
		    <el-tab-pane label="成员列表" >
				<div class="search-form">
				    <el-form ref="form" :inline="true"  :model="search_key" prop="">
				        <el-form-item label="姓名">
				            <el-input v-model="search_key.name" placeholder="输入姓名或用户名或企业人员名称" style="width:200px;"></el-input>
				        </el-form-item>
				        <el-form-item label="状态">
				            <el-select v-model="search_key.status" placeholder="请选择">
				                <el-option label="在职" :value="1"></el-option>
				                <el-option label="离职" :value="0"></el-option>
				            </el-select>
				        </el-form-item>
				       <el-form-item label="时间范围">
				             <el-date-picker
				                v-model="search_key.timeSpan"
				                type="datetimerange"
				                range-separator="至"
				                start-placeholder="开始日期"
				                end-placeholder="结束日期"
				                value-format="yyyy-MM-dd HH:mm:ss">
				            </el-date-picker>
				         </el-form-item>
				        <el-button type="primary" @click="getData()">查询</el-button>
				    </el-form>
				 </div>
				 <div style="margin-bottom:10px;">
				    <el-button size="small" type="" @click="handleAdd()">添加</el-button>
				    <el-button size="small" type="" @click="handleUpdateDialog()">更新企业成员</el-button>
				 </div>
				  <el-table
				    :data="tableData" 
				    style="width: 100%">
				    <el-table-column
				       type="selection">
				    </el-table-column>
				    <el-table-column
				       prop="id"
				       label="ID">
				    </el-table-column>
				    <el-table-column
				        label="头像">
				        <template slot-scope="scope">
				            <el-image :src=scope.row.avatar style="width:30px;"></el-image>
				        </template>
				    </el-table-column>
				     <!-- <el-table-column
				       label="企业人员">
				       <template slot-scope="scope">
				           <span v-if="scope.row.corp_name">{{scope.row.corp_name}}</span>
				           <span v-else  style="color:#E6A23C;">未绑定</span>
				       </template>
				    </el-table-column> -->
				     <el-table-column
				       prop="nickname"
				       label="用户名">
				    </el-table-column>
				    <el-table-column
				       prop="name"
				       label="姓名">
				    </el-table-column>
				    <el-table-column
				        prop="department_name"
				        label="部门">
				    </el-table-column>
				      <el-table-column
				        prop="role_name"
				        label="系统角色">
				        <template slot-scope="scope">
				            <span style="color:#409EFF;">{{scope.row.role_name}}</span>
				        </template>
				    </el-table-column>
				    <el-table-column
				       prop="mobile"
				       label="手机号">
				    </el-table-column>
				     <el-table-column
				       prop="biz_mail"
				       label="邮箱">
				    </el-table-column>
				    <el-table-column
				        prop="position"
				        label="职务信息">
				    </el-table-column>
				    <el-table-column
						width="200"
				       label="">
					   <template slot="header">
						    <p>离职/在职<i class="el-icon-question" style="color:#e0e0e0;" @click="questionOpen('成员离职后，不可重新开启在职状态；请谨慎操作')"></i></p>
					   </template>
				       <template slot-scope="scope">
				           <!-- <span v-if="scope.row.status == 1">在职</span>
				            <span v-else>离职</span> -->
							<el-switch
							  v-model="scope.row.status"
							  :disabled="scope.row.switch_status"
							  @change="handleSwithStatus(scope.row)"
							  :active-value="1"
							  :inactive-value="0"
							  >
							</el-switch>
				       </template>
				    </el-table-column>
					<el-table-column
					    prop="work_msg_status"
					    label="存档状态">
						<template slot-scope="scope">
							<span v-if="scope.row.work_msg_status==1">已开启</span>
							<span v-else>未开启</span>
						</template>
					</el-table-column>
					<el-table-column
					    prop="update_time"
					    label="最近登录">
					</el-table-column>
					<el-table-column
					    prop="ip"
					    label="最近IP">
					</el-table-column>
				    <el-table-column
				        prop="create_time"
				        label="创建时间">
					</el-table-column>
					
				   <el-table-column
				    fixed="right"
				    width="240px"
				    label="操作">
				    <template slot-scope="scope">
				        <el-button  size="small" @click="handleAdd(scope.row.id)">编辑</el-button>
				       <!-- <el-button 	v-if="scope.row.status ==1"  size="small" type="primary" plain  @click="handleStatus(scope.row)">更新状态</el-button> -->
				       <el-button v-if="scope.row.create_mode == 0"  size="small" type="danger" plain  @click="handleDel(scope.row)">删除</el-button>
				    </template> 
				    </el-table-column>  
				 </el-table>
				<!-- <el-pagination
				    @size-change="handleSizeChange"
				    @current-change="handleCurrentChange"
				    :page-size="pagesize"
				    layout="total,sizes,prev,pager,next,jumper"
				    :total="total">
				</el-pagination> -->
				<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
				    <!-- 更新状态 -->
				  <el-dialog title="状态弹窗" :visible.sync="dialogStatusVisible" width="30%">
				    <el-form ref="formStatus" :model="formStatus" prop="" label-width="100px">
				        <el-form-item label="当前状态" prop="old_status">
				            <el-input v-model="formStatus.old_status" style="width:220px" :disabled="true"></el-input>
				        </el-form-item>
				        <el-form-item label="更改为" prop="new_status">
				           <el-select v-model="formStatus.new_status" placeholder="请选择">
				                <el-option label="请选择" :value="2"></el-option>
				                <el-option label="在职"   :value="3"></el-option>
				                <el-option label="已离职" :value="1"></el-option>
				            </el-select>
				        </el-form-item>
				    </el-form>
				    <div slot="footer" class="dialog-footer">
				        <el-button @click="dialogStatusVisible = false">取 消</el-button>
				        <el-button type="primary" @click="confirm()">确 定</el-button>
				    </div>
				</el-dialog>
				
				<!-- 添加管理员 -->
				<AddVue ref="addChildDialog"></AddVue>
				
				<el-dialog title="选择更新成员的企业" :visible.sync='dialogUpdateVisible'  width="30%" :close-on-click-modal="false" >
				   <el-form ref="form" :model="form" prop="" :rules="rules" label-width="100px">
					   <el-form-item label="企业" prop="gender">
						   <template>
							   <el-radio v-model="form.coid_type" :label="0">当前登录企业</el-radio>
							   <el-radio v-model="form.coid_type" :label="1">指定企业</el-radio>
						   </template>
					   </el-form-item>
					   <el-form-item label="企业" prop="" v-if="form.coid_type == 1">
						   <el-select v-model="form.coid" placeholder="请选择" style="width:100%">
								<el-option v-for="(item,index) in coList" :key="index" :label="item.title" :value="item.id"></el-option>
						   </el-select>
					   </el-form-item>
				   </el-form>
				   <div slot="footer" class="dialog-footer">
					   <el-button @click="dialogUpdateVisible = false">取 消</el-button>
					   <el-button type="primary" @click="handlUpdatePer()">更 新</el-button>
				   </div>
				</el-dialog>
			</el-tab-pane>
		</el-tabs>
			
    </div>
</template>
<script>
import AddVue from "./Add.vue"
import  TransferVue from './Transfer.vue'
import  AssignVue from  './AssignList.vue'
export default {
    inject: ["reload"],
	components:{
		AddVue,
		TransferVue,
		AssignVue
	},
    data() {
        return {
			active:0,
            formStatus: {
                old_status: 0,
                new_status: 0
            },
            // uploadUrl: "/api/admin/Upload/images",
            imageUrl: "",
            form: {
                icon: "",
                role_id: '',
                name: "",
                nickname: "",
                gender: 1,
                password: "",
                mobile: "",
                status: 1,
                position:''
            },
            roleList: [],
            corpList:[],   
            dialogStatusVisible: false,
            dialogAddVisible: false,
            search_key: {
                name: "",
                status: "",
                timeSpan:''
            },
            tableData: [],

            currentPage:1,
            pagesize:10,
            total:0,
			dialogUpdateVisible:false,
			form:{
			  coid_type:0,
			  coid:''
			},
        };
    },
    created() {
		var that  = this
        this.corpList = JSON.parse(localStorage.getItem('companyList'))
        this.getData();
        if (this.$route.query.dialogAddVisible) {   //从我的资料跳转
            this.$router.push({query:{main_title:'账号管理'}})  
            // this.handleEdit(localStorage.getItem('admin_id'))
			this.handleAdd(localStorage.getItem('admin_id'))
        }
        
    },
    mounted() {
    },
    methods: {
        getData() {
            var arr = []
            arr['page'] = this.currentPage
            arr['limit'] = this.pagesize
            arr['name'] = this.search_key.name
            arr['status'] = this.search_key.status
            arr['timeSpan'] = this.search_key.timeSpan
            var params = {...arr}
            this.$api.post("Admin/index", params, res => {
                if (res.code == 200) {
					let i = 0
					for(i;i<res.data.data.length;i++){
						res.data.data[i]['switch_status'] = res.data.data[i]['status'] == 1?false:true
					}
                    this.tableData = res.data.data;
					console.log('table:',this.tableData)
                    this.total  = res.data.count
                }
            });
        },
		questionOpen(msg){
		   this.$message(msg)
		},
        handleAdd(id=0) {		//添加成员 
			this.$nextTick(()=>{
				this.$refs.addChildDialog.showDialog(id)
			})
        },
		
		handleSwithStatus(row){
			// let status = row.status == 1 ? 0 : 1;
			if(row.status == 1){
				this.$message.error('离职后无法重新启用成员')
				this.reload()
				return false
			}
			// let txt = row.status == 1 ? "离职" : "在职";
			this.$alert("离职后无法恢复！确定成员已离职吗？", "更新提示", {
			    callback: action => {
					console.log(action)
			        if (action == "confirm") {
			            this.$api.put("Admin/updateStatus", { "id": row.id, "status": row.status }, res => {
			                if (res.code == 200) {
			                    this.$message.success(res.msg);
			                    this.reload();
			                }
			            });
			        }else if (action == "cancel") {
			            this.reload();
			        }
			    }
			});
		},
        handleClick(row) {
            
        },
        // handleStatus(row) {
        //     let status = row.status == 1 ? 0 : 1;
        //     let txt = row.status == 1 ? "离职" : "在职";
        //     this.$alert("确定将状态更为" + txt + "吗？", "更新提示", {
        //         callback: action => {
        //             if (action == "confirm") {
        //                 this.$api.put("Admin/updateStatus", { "id": row.id, "status": status }, res => {
        //                     if (res.code == 200) {
        //                         this.$message.success(res.msg);
        //                         this.reload();
        //                     }
        //                 });
        //             }
        //             else if (action == "cancle") {
        //                 this.reload();
        //             }
        //         }
        //     });
        // },
        handleDel(row) {
            this.$alert("确定删除该成员吗？（将同步删除企业微信里成员）", "删除提示", {
                // confirmButtonText:'确定',
                showConfirmButton: true,
                showCancelButton: true,
                callback: action => {
                    if (action == "confirm") {
                        this.$api.delete("Admin/del", { "id": row.id }, res => {
                            if (res.code == 200) {
                                this.$message.success(res.msg);
                                this.reload();
                            }
                        });
                    }
                    else if (action == "cancel") {
                        this.reload();
                    }
                }
            });
        },
		handleUpdateDialog(){
			   this.$api.post('Company/getCompanyList',null,res=>{
				   if(res.code == 200){
					   this.coList =  res.data
					   this.dialogUpdateVisible =  true
				   }
			   })
		  
		},
        handlUpdatePer(){
            this.$alert("更新企业成员后会自动以企业成员账号创建系统登录账号", "更新人员提示", {
                // confirmButtonText:'确定',
                showConfirmButton: true,
                showCancelButton: true,
                callback: action => {
                    if (action == "confirm") {
						const loading = this.$loading({
						   lock: true,
						   text: '同步数据中，需要点时间；请勿关闭',
						   spinner: 'el-icon-loading',
						   background: 'rgba(0, 0, 0, 0.7)'
						});
						let params = null
						if(this.form.coid){
							 params = {coid:this.form.coid}
						}
                        this.$api.post("Admin/updatePer",params,res=>{
                            if (res.code == 200) {
                                this.$message.success(res.msg) 
                                this.reload()
                            }else{
                                this.$error(res.msg)
                            }
							loading.close()
                        })
                    }
                    else if (action == "cancel") {
                        // this.reload();
                    }
                }
            });
        },
        
        getCurrPage(val){   //获取当前页
            this.currentPage =  val
            this.getData()
        },
        getPageSize(val){   //获取每页条数
            this.pagesize =  val
             this.getData()
        },
    },
}
</script>
<style  lang="scss">
.page-container{
    .admin-dialog .el-input{
        width:500px;
    }
    .search-form{
        height: 60px;
        margin:20px 0px ;
        // margin-top: 20px;
        background: #f2f2f2;
        padding: 20px 0px 0px 15px;
    }
     .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9 !important;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        .el-upload:hover {
        border-color: #409EFF;
    }
     .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 118px;
        height: 118px;
        line-height: 118px;
        text-align: center;
    }
     .avatar {
        width: 118px;
        height: 118px;
        display: block;
    }
  }

}

</style>