<template>
<div>
	<div >
	    <el-form ref="form"  :model="search_key" prop="">
			<div  style="position: absolute;right:0;z-index: 999;">
				<el-button  type="primary" @click="handleExport">导出</el-button>
			</div>
			<el-form-item label="接替成员" >
				<el-checkbox-group v-model="search_key.new_admin_id" @change="getData()">
					<el-checkbox   v-for="(item,index) in corpUserList" :label="item.id" :key="index">{{item.corp_name}}</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			
			<el-form-item label="状态" >
			    <el-checkbox-group v-model="search_key.status" @change="getData()">
			        <el-checkbox :label='1'>接替完毕</el-checkbox>
			        <el-checkbox :label='2'>等待接替</el-checkbox>
					<el-checkbox :label='3'>客户拒绝</el-checkbox>
					<el-checkbox :label='4'>接替成员客户已达到上限</el-checkbox>
					<el-checkbox :label='5'>未分配成功</el-checkbox>
			    </el-checkbox-group>
			</el-form-item>
			<el-form-item label="继承类型" >
			    <el-checkbox-group v-model="search_key.type" @change="getData()">
			        <el-checkbox :label='0'>在职继承</el-checkbox>
			        <el-checkbox :label='1'>离职继承</el-checkbox>
			    </el-checkbox-group>
			</el-form-item>
	    </el-form>
	</div>
	<el-table
	   :data="tableData" 
		style="width: 100%">	
		<el-table-column
		   prop="name"
		   label="名称">
		</el-table-column>
		<el-table-column
		   prop="corp_userid"
		   label="接替成员">
		   <template slot-scope="scope">
			   <span>{{scope.row.new_admin.corp_name}}</span>
		   </template>
		</el-table-column>
		<el-table-column
			prop="corp_userid"
			label="原跟进成员">
			<template slot-scope="scope">
				<span>{{scope.row.old_admin.corp_name}}</span>
			</template>
		</el-table-column>
		<el-table-column
			prop="desc"
			label="转移原因">	
		</el-table-column>
		<el-table-column
			prop=""
			label="接替状态">	
			<template slot-scope="scope">
				<span v-if="scope.row.status == 5" style="color: #E6A23C">{{scope.row.status_text}}</span>
				<span v-else style="color: #67C23A">{{scope.row.status_text}}</span>
				<!-- <div v-html="scope.row.status_text"></div> -->
			</template>
		</el-table-column>
		<el-table-column
			prop="type_text"
			label="继承类型">	
		</el-table-column>
	</el-table>
	<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view>   
</div>
</template>

<script>
export default {
    data() {
        return {
			search_key:{
				new_admin_id:[],
				status:[],
				type:[]
			},
			tableData:[],
			currentPage:1,
			pagesize:10,
			total:0,
			corpUserList:[]
        }
    },
    created() {
		
		this.getData();
    },
    mounted() {

    },
    methods:{
		getData(){
			this.$api.post('admin/assignList',{
				new_admin_id:this.search_key.new_admin_id,
				status:this.search_key.status,
				type:this.search_key.type,
				page:this.currentPage,
				limit:this.pagesize
			},res=>{
				if(res.code == 200){
					this.tableData = res.data.list;
					this.total  = res.data.count
					this.corpUserList =  res.data.corp_user_list
				}
			})	
		},
		handleExport(){		//导出
			console.log('test')
			this.$api.post('Admin/exportAssign',this.search_key,res=>{
			    if(res.code == 200){
			        location.href = '/api/admin/Admin/exportAssign?key='+res.data.key
			    }else{
			        this.$message.error(res.msg)
			    }
			})
		},
		getCurrPage(val){   //获取当前页
		    this.currentPage =  val
		},
		getPageSize(val){   //获取每页条数
		    this.pagesize =  val
		},
    },
}
</script>
<style scoped>

</style>