<template>
<div>
	
	<div style="margin-bottom:10px;display: flex;" >
	    <el-button size="small" type="" @click="handlUpdatePer()">同步离职成员</el-button>
		
		<div style="width: 90%;text-align: right;">
			<span style="color: #409EFF;font-size: 12px;margin-right: 10px;font-weight: bold;">已选中{{num}}个成员</span>
			<el-button size="small" type="primary"  @click="handleTransfer(0)">分配给其他成员</el-button>
		</div>
	</div>
	<el-table
	   :data="tableData" 
	   :row-key="getRowKeys"
	    @selection-change="handleSelectionChange"
		style="width: 100%">
			<el-table-column
				 :reserve-selection="true"
				type="selection"
				width="55">
			</el-table-column>
			<el-table-column
			   prop="id"
			   label="ID">
			</el-table-column>
			<el-table-column
			   prop="corp_name"
			   label="离职成员">
			</el-table-column>
			<el-table-column
			   prop="count_customer"
			   label="待分配客户">
			</el-table-column>
			<el-table-column
			   prop="count_chat"
			   label="待分配群聊">
			</el-table-column>
			<el-table-column
			   prop="dimission_time"
			   label="离职时间">
			</el-table-column>
			<!-- <el-table-column
			   prop=""
			   label="资源分配状态">
			   <template slot-scope="scope">
				   <span v-if="scope.row.resource_status == 1">未分配</span>
				   <span v-if="scope.row.resource_status == 2">已分配</span>
				   <span v-else>……</span>
			   </template>
			</el-table-column> -->
	   </el-table>
	   
	   <el-dialog title="离职资产分配"
			:close-on-click-modal="false"
	        :visible.sync="dialogTransferVsible" 
		    width="45%">
			 <el-form ref="form"  :model="transferForm" label-width="150px">
			        <el-form-item label="接替人" prop="">
			             <el-select v-model="transferForm.new_userid" style="width: 300px;">
			                 <el-option v-for="item in corpUserList" :key="item.id" :label="item.corp_name" :value="item.id">
								 <div  style="display: flex;align-items: center;">
								 	<el-image style="width:25px;height:25px;" :src="item.avatar"></el-image>
								 	<span style="margin-left: 10px;">{{item.corp_name}}</span>
								 </div>
							 </el-option>
			             </el-select>
			         </el-form-item>
			         <el-form-item label="备注" prop="">
			             <el-input type="textarea" rows="7" v-model="transferForm.desc"></el-input>
			         </el-form-item>
			     </el-form>
			  <div slot="footer" class="dialog-footer">
			     <el-button @click="dialogTransferVsible = false">取 消</el-button>
			     <el-button type="primary" @click="handleTransfer(1)">确 定</el-button>
			 </div>
		</el-dialog>
</div>
</template>

<script>
export default {
	inject:['reload'],
    data() {
        return {
			tableData:[],
			currentPage:1,
			pagesize:10,
			total:0,
			multipleSelection:[],
			getRowKeys(row){
			    return row.id
			},
			num:0,
			transferForm:{
				new_userid:'',				//接替者
				desc:'离职资产分配'
			},
			dialogTransferVsible:false
			
        }
    },
    created() {
		this.getData()
    },
    mounted() {

    },
    methods:{
		getData(){		//离职成员数据
			this.$api.post('admin/quitList',{
				page:this.currentPage,
				limit:this.pagesize
			},res=>{
				if(res.code == 200){
					this.tableData = res.data.list;
					this.total  = res.data.count
				}
			})
		},
		handlUpdatePer(){		//更新离职成员数据
			// const loading = this.$loading({
			//    lock: true,
			//    text: '同步数据中，需要点时间；请勿关闭',
			//    spinner: 'el-icon-loading',
			//    background: 'rgba(0, 0, 0, 0.7)'
			// });
			this.$api.put('admin/updateQuit',null,res=>{
				if(res.code == 200){ 
					this.$message.success(res.msg)	
				}else{
					this.$message.error(res.msg)
				}
				// loading.close()
			})
		},
		//监听表格多选框
		handleSelectionChange(val){
		    this.multipleSelection  = []
		    var list = val;
		     list.forEach(item=>{
		         this.multipleSelection.push(item.id )
		     })
			 this.num = this.multipleSelection.length
			 console.log(this.multipleSelection)
		},
		handleTransfer(type=0){		//确定资产分配
			if(type == 0){
			    this.$api.get('Admin/getCorpList',null,res=>{
			        if(res.code == 200){
			            this.corpUserList = res.data.corp_user_list
			            this.dialogTransferVsible = true
			        }
			    })
			}else{
			    let params =  {
						id:this.multipleSelection,
						new_userid:this.transferForm.new_userid,
						desc:this.transferForm.desc,
						type:1
					}
			    this.$api.post('admin/transfer',params,res=>{
			        if(res.code == 200){
			            this.$message.success(res.msg)
			            this.reload()
			        }else{
			            this.$message.error(res.msg)
			        }
			    })
			}
		}
    },
}
</script>
<style scoped>

</style>